<template>
	<el-dialog
		:visible.sync="visible"
		:lock-scroll="false"
    :append-to-body="true"
		:close-on-click-modal="false"
		width="48%"
		@close="close"
		center
	>
		<div class="content">
			<div class="top">
				<div class="top-left">
					<div class="title">缴费信息</div>
					<div class="text-area">
						<div class="text-item">
							<span class="lable">项目编号：</span>
							<span class="value">{{ payInfo.xmbh }}</span>
						</div>
						<div class="text-item">
							<span class="lable">项目名称：</span>
							<span class="value">{{ payInfo.xmmc }}</span>
						</div>
						<div class="text-item">
							<span class="lable">保证金：</span>
							<span class="value">{{ payInfo.jybzj }}</span>
						</div>
						<div class="line"></div>
						<div class="text-item">
							<span class="lable">保证金收款账户：</span>
							<span class="value">{{ payInfo.accountNum }}</span>
						</div>
						<div class="text-item">
							<span class="lable">账户名称：</span>
							<span class="value">{{ payInfo.accountName }}</span>
						</div>
						<div class="text-item">
							<span class="lable">开户行：</span>
							<span class="value">{{ payInfo.accountBank }}</span>
						</div>
						<div class="img">
							<img v-if="payInfo.accountCode" :src="payInfo.accountCode" alt="" />
              <img v-else src="imgs/none.png" alt="" />
              <div class="text">开户行收款码</div>
						</div>
					</div>
				</div>
				<div class="top-line"></div>
				<div class="top-right">
					<div class="title">报名人信息</div>
					<div class="text-area">
						<div class="text-item">
							<span class="lable" v-if="identityObj.identityTypeCode == '2'">姓名：</span>
							<span class="label" v-else>企业名称：</span>
							<span class="value">{{ identityObj.identityName }}</span>
						</div>
						<div class="text-item">
							<span class="lable" v-if="identityObj.identityTypeCode == '2'">身份证号：</span>
							<span class="lable" v-else>统一社会信用代码：</span>
							<span class="value" v-if="identityObj.identityTypeCode == '2'">{{ identityObj.identityCode }}</span>
							<span class="value" v-else>{{ identityObj.identityCode }}</span>
						</div>
						<div class="text-item">
							<span class="lable">联系人：</span>
							<span class="value">{{ identityObj.contactName }}</span>
						</div>
						<div class="text-item">
							<span class="lable">联系方式：</span>
							<span class="value">{{ identityObj.contactPhone }}</span>
						</div>
            <div class="text-item">
							<span class="lable">地址：</span>
							<span class="value">{{ identityObj.areaFullName }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="middle">
				<span>以上信息核对无误后缴费，缴费时请备注如下格式内容：</span>
				<span>{{ payInfo.xmbh }}，{{ payInfo.xmmc }}保证金缴纳。</span>
				<span>中标后保证金将全额转入出让方账户，未中标将原路返回支付账户。</span>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button type="success" style="width: 150px" @click="close">关闭</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { showAccount } from '@/api/projectManage'
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			data: {},
			checked: false,
		}
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		payInfo: {
			type: Object,
			default: function () {
				return {}
			},
		},
	},
	watch: {
		payInfo: function () {
			if (this.payInfo.accountCode) {
				this.$nextTick(() => {
					this.payInfo.accountCode =
						window.globalUrl.HOME_API + 'admin/file/get?ossFilePath=' + this.payInfo.accountCode
				})
			}
		},
	},
  computed: mapGetters(['identityObj']),
	methods: {
		close() {
			// 关闭
			this.$emit('close', false)
		},
	},
}
</script>

<style lang="less" scoped>
.content {
	position: relative;
	width: 100%;
	.top {
		background: #467ef5;
		display: grid;
		grid-template-columns: 320px 20px 1fr;
		padding: 10px;
		border-radius: 8px;
		color: #ffffff;
		.title {
			font-size: 18px;
			font-weight: 500px;
			border-bottom: 1px solid #ffffff;
			line-height: 30px;
			.text-area {
				font-size: 14px;
				line-height: 30px;
			}
			.text-item {
				display: flex !important;
				justify-content: center;
				> span {
					display: inline-block;
				}
			}
		}
	}
	.middle {
		width: 60%;
		margin: 10px auto;
		background: red;
		display: flex;
		flex-flow: column;
		border-radius: 4px;
		> span {
			display: block;
			color: #ffffff;
			line-height: 30px;
			font-size: 14px;
			font-weight: 500;
			text-align: center;
		}
	}
}

.line {
	height: 2px;
	background: #ffffff;
	margin: 5px;
}
.img {
  display: flex;
  flex-flow: column;
  gap: 5px;
  justify-content: center;
  margin: 20px 0;
  > img {
    display: inline-block;
    max-width: 200px;
    margin: 0 auto;
  }
  .text {
    text-align: center;
  }
}
.agree {
	margin-top: 12px;
}
/deep/.el-dialog__header,
/deep/.el-dialog__body,
/deep/.el-dialog__footer {
	background: #2452b3 !important;
}
</style>
